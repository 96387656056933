.titleReport {
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
}

.imageReport {
  text-align: center;
  margin-top: 1%;
  margin-bottom: 1%;
}

.contentReport {
  font-size: 1.2rem;
}

.titleEspelho {
  font-size: 1.8rem;
}

.messageReport {
  font-size: 1rem;
  text-align: left;
  margin-right: auto;
  font-style: italic;
}

.signatureReport {
  text-align: right;
  margin-right: auto;
  font-size: 1.1rem;
  font-family: Arial, "Times New Roman", Times, monospace, serif;
  font-style: italic;
}

.navbarLink {
  font-size: 1.1rem;
  color: black;
  font-weight: bold;
}

.dropDownStyle {
  font-size: 1rem;
  color: #007bff;
  font-weight: bold;
}

.preContent {
  white-space: pre-wrap;
  font-size: 16;
  font-family: Arial, "Times New Roman", Times, monospace, serif;
  text-align: justify;
  border: 0px;
  background-color: transparent;
}

.styleMenu {
  border: '1px solid blue';
  display: block;
  font-size: smaller;
  line-height: 7px;
  transition: top 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}

.nextAppts {
  background-color: #34a647 !important;
  color: white !important;
}
